<template>
  <c-box
    w="100%"
    mx="auto"
    :p="['1rem', '0px']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: { name: 'client.index' } },
          { label: 'Akun', isCurrent: true },
        ]"
      />
    </Portal>
    <c-flex
      flex-direction="column"
      bg="white"
      border="1px solid #f2f2f2"
      box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
      border-radius="16px"
      w="100%"
      :p="['10px', '24px']"
    >
      <c-text
        :d="['none', 'block']"
        align="center"
        mx="auto"
        font-family="Roboto"
        font-size="28px"
        color="#111"
        font-weight="700"
      >
        Data Klien
      </c-text>
      <c-flex
        v-if="profile.dataProfile"
        :p="['8px', '16px']"
        align-items="center"
        :width="['100%', '600px']"
        mx="auto"
        mb="16px"
      >
        <c-image
          :src="getPhotoUser(profile.dataProfile.photoUrl)"
          :alt="fullName"
          :w="['100px', '150px']"
          :h="['100px', '150px']"
          rounded="full"
          object-fit="cover"
        />
        <c-flex
          flex-grow="1"
          flex-direction="column"
          justify="center"
          :ml="['1.5rem', '2rem']"
        >
          <c-text
            color="#111"
            font-weight="400"
            :font-size="['12px', '16px']"
            font-family="Roboto"
          >
            Nama
          </c-text>
          <c-text
            :mt="['0px', '4px']"
            color="#000"
            font-weight="500"
            :font-size="['14px', '18px']"
            font-family="Roboto"
          >
            {{ fullName }}
          </c-text>

          <c-text
            :mt="['8px', '16px']"
            font-weight="400"
            :font-size="['12px', '16px']"
            font-family="Roboto"
          >
            Nomor Handphone
          </c-text>
          <c-text
            :mt="['0px', '4px']"
            color="#000"
            font-weight="500"
            :font-size="['14px', '18px']"
            font-family="Roboto"
          >
            {{ profile.dataProfile.phone }}
          </c-text>

          <c-text
            :mt="['8px', '16px']"
            font-weight="400"
            :font-size="['12px', '16px']"
            font-family="Roboto"
          >
            Email
          </c-text>
          <c-text
            :mt="['0px', '4px']"
            color="#000"
            font-weight="500"
            :font-size="['14px', '18px']"
            font-family="Roboto"
          >
            {{ profile.dataProfile.email }}
          </c-text>
        </c-flex>
      </c-flex>
      <SkeletonProfile v-else />
      <c-flex
        :flex-direction="['column', 'column-reverse']"
        :gap="['10px', '16px']"
      >
        <ActiveProgram type="profile" />
        <c-box :padding="['0 16px', 'unset']">
          <CardInfoReferral type="profile" />
        </c-box>
      </c-flex>
      <c-box
        mt="16px"
        :d="['block', 'none']"
      >
        <menu-item
          title="Akun"
          :to="$router.resolve({ name: 'client.profile.account' }).route.fullPath"
          :border-top="true"
        />
        <menu-item
          title="Riwayat Program"
          :to="$router.resolve({ name: 'client.profile.programHistory' }).route.fullPath"
        />
        <menu-item
          title="Riwayat Transaksi"
          :to="$router.resolve({ name: 'client.profile.historyTransaction' }).route.fullPath"
        />
        <menu-item
          title="Bahasa"
          :to="$router.resolve({ name: 'under-maintenance' }).route.fullPath"
        />
        <!--          :to="$router.resolve({ name: 'client.profile.language' }).route.fullPath"-->
        <menu-item
          title="Pusat Bantuan"
          :to="$router.resolve({ name: 'under-maintenance' }).route.fullPath"
        />
        <!--          :to="$router.resolve({ name: 'client.profile.helpcenter' }).route.fullPath"-->
        <c-flex
          as="button"
          h="64px"
          p="16px"
          w="100%"
          justify-content="space-between"
          align-items="center"
          @click="openModalConfirm"
        >
          <c-text
            font-family="Roboto"
            color="red.900"
            font-weight="500"
          >
            Logout
          </c-text>
          <c-image
            :src="require('@/assets/ic-chevron-right-black.svg')"
            alt="icon arrow right"
          />
        </c-flex>
      </c-box>
    </c-flex>
    <ModalConfirmLogout
      v-show="isConfirm"
      :is-open="isConfirm"
      @handle-close="handleCloseConfirmModal"
      @logOut="logout"
    />
  </c-box>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MenuItem from './menu-item.vue'
import generalMixin from '@/utils/general-mixins'
import ActiveProgram from '@/components/homepage/active-program.vue'
import SkeletonProfile from '@/components/skeletons/skeleton-profile.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import ModalConfirmLogout from '@/components/widgets/modal-confirm-logout.vue'
import CardInfoReferral from '@/components/card-info-referral.vue'

export default {
  name: 'ProfilePage',
  components: {
    CardInfoReferral,
    MenuItem,
    ActiveProgram,
    SkeletonProfile,
    BreadcrumbPath,
    ModalConfirmLogout,
  },
  mixins: [generalMixin],
  data() {
    return {
      isConfirm: false,
    }
  },
  computed: {
    ...mapState({
      profile: (s) => s.profile,
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
    ...mapGetters({
      fullName: 'profile/fullName',
    }),
  },
  mounted() {
    if (this.role === 'client') {
      this.getProfileClient()
    }
    if (this.role === 'nutritionist') {
      this.getProfile()
    }
  },
  methods: {
    ...mapActions({
      // client
      getProfileClient: 'profile/getProfileClient',
      getProfile: 'profile/getProfile',
      signout: 'auth/signout',
    }),
    logout() {
      this.signout()
        .then(() => {
          this.$store.dispatch('footer/getFooter')
          this.$store.commit('resetStore')
          this.$router.replace('/')
          if (this.$route.name.includes('admin')) {
            this.$router.replace({ name: 'auth', query: { tab: 'login' } })
          }
        })
        .catch(() => {
          this.$router.replace('/')
        })
    },
    openModalConfirm() {
      this.isConfirm = true
    },
    handleCloseConfirmModal() {
      this.isConfirm = false
    },
  },
}
</script>
